import { useRef } from 'react';
import { Button, Modal } from 'reactstrap';

import IconNext from '../../assets/icons/arrowForward';
import IconBack from '../../assets/icons/arrowBack';
import CloseIcon from '../../assets/icons/close';
import * as S from './styles';
import { useState } from 'react';
import RenderItem from '../CarouselItem';

const RenderNextArrow = ({ onClick, className }) => {
  return (
    <S.WrapNextButton>
      <Button
        onClick={onClick}
        color={className.includes('slick-disabled') ? 'secondary' : 'primary'}
        disabled={className.includes('slick-disabled')}
      >
        <S.WrapIcon>
          <IconNext />
        </S.WrapIcon>
      </Button>
    </S.WrapNextButton>
  );
};

const RenderPrevArrow = ({ className, onClick }) => {
  return (
    <S.WrapPrevButton>
      <Button
        onClick={onClick}
        color={className.includes('slick-disabled') ? 'secondary' : 'primary'}
        disabled={className.includes('slick-disabled')}
      >
        <S.WrapIcon>
          <IconBack />
        </S.WrapIcon>
      </Button>
    </S.WrapPrevButton>
  );
};

const Carousel = ({ items = {}, index }) => {
  const carouselRef = useRef();

  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemToOpen, setItemToOpen] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const afterChange = (data) => {
    setCurrentIndex(data + 1);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    afterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  if (!items[index]?.length) {
    return <></>;
  }

  return (
    <>
      <S.Slider ref={carouselRef} {...settings}>
        {items[index].map((item, index) => (
          <RenderItem
            item={item}
            key={index}
            onClick={() => {
              setItemToOpen(index);
              setIsOpen(true);
            }}
          />
        ))}
      </S.Slider>

      <S.Counter>
        {currentIndex} de {items[index]?.length}
      </S.Counter>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        size="xl"
        centered
      >
        <S.WrapIconClose>
          <Button color="primary" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </Button>
        </S.WrapIconClose>
        <S.WrapImageModal>
          <img src={items[index][itemToOpen]?.src || ''} alt="" />
          <S.Counter>
            {currentIndex} de {items[index]?.length}
          </S.Counter>

          <RenderNextArrow
            onClick={() => {
              let nextIndex = itemToOpen + 1;
              if (nextIndex >= items[index]?.length) {
                nextIndex = 0;
              }
              setItemToOpen(nextIndex);
              carouselRef.current.slickGoTo(nextIndex);
            }}
            className=""
          />
          <RenderPrevArrow
            onClick={() => {
              let nextIndex = itemToOpen - 1;
              if (nextIndex < 0) {
                nextIndex = items[index]?.length - 1;
              }
              setItemToOpen(nextIndex);
              carouselRef.current.slickGoTo(nextIndex);
            }}
            className=""
          />
        </S.WrapImageModal>
      </Modal>
    </>
  );
};

export default Carousel;
