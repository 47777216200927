import styled from 'styled-components';
import SliderSlick from 'react-slick';

export const Slider = styled(SliderSlick)`
  padding: 0 50px;
  margin: 20px 0;
  position: relative;
`;

export const WrapIcon = styled.div`
  svg {
    width: 20px;
    padding-bottom: 2px;
  }
`;

export const WrapOpen = styled.div`
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 20px;

  .btn {
    padding: 5px;
    line-height: 1;
  }

  svg {
    width: 15px;
  }
`;

export const WrapNextButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;

  transform: translate(0, -50%);
`;

export const WrapPrevButton = styled.div`
  position: absolute;
  left: 0;
  top: 50%;

  transform: translate(0, -50%);
`;

export const Counter = styled.span`
  font-size: 18px;
  text-align: center;
  width: 100%;
  display: block;
`;

export const WrapImage = styled.div`
  margin: auto;
  width: 150px;
`;

export const WrapImageModal = styled.div`
  height: 80vh;
  width: 100%;

  img {
    width: 100%;
    height: calc(100% - 30px);
    object-fit: contain;
  }
`;

export const WrapIconClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  .btn {
    padding: 5px;
    line-height: 1;
  }
  svg {
    width: 20px;
  }
`;

export const WrapCarouselItem = styled.div`
  position: relative;
  width: 100%;
  height: 240px;

  padding: 0 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;
